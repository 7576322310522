import LazyLoad from "vanilla-lazyload";

var lazyLoadInstances = [];

var initOneLazyLoad = function (horizContainerElement) {
  var oneLL = new LazyLoad({
    container: horizContainerElement
  });
  lazyLoadInstances.push(oneLL);
};

var lazyLazy = new LazyLoad({
  elements_selector: ".blogpost__image",
  callback_enter: initOneLazyLoad,
  unobserve_entered: true
});

document.addEventListener('DOMContentLoaded', function() {
  function calculateMinRead() {
    let blogArticle = document.querySelector('.blog-article');
    let minRead = blogArticle.textContent.replace(/^\s+|\s+$|\s+(?=\s)/g, "").split(' ').length / 275 + (12 / 60 * blogArticle.querySelectorAll("img").length)
    return Math.round(minRead) || 1;
  };
  if (document.querySelector('.min-read-js')) {
    document.querySelector('.min-read-js').innerHTML = `${calculateMinRead()} min read`;
    document.querySelector('.min-read-js').classList.remove("d-none");
  };

  document.addEventListener('mainnav-show', function() {
    $('#blog-navbar-toggle').collapse('hide');
    document.getElementById('blog-navbar').classList.add('d-none');
    document.getElementById('blog-navbar').classList.remove('fixed-top');
  })

  document.addEventListener('mainnav-hide', function() {
    document.getElementById('blog-navbar').classList.remove('d-none');
    document.getElementById('blog-navbar').classList.add('fixed-top');
  })

  document.querySelector('.blog-back-to-top').addEventListener('click', function() {
    window.scrollTo(0,0);
  });

  function getDocHeight() {
    var D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    );
  };

  function amountscrolled(){
    var winheight= window.innerHeight || (document.documentElement || document.body).clientHeight;
    var docheight = getDocHeight();
    var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
    var trackLength = docheight - winheight;
    var pctScrolled = Math.floor(scrollTop/trackLength * 100);
    if (pctScrolled >= 50) {
      document.querySelector('.blog-back-to-top').style.display = 'block';
    } else {
      document.querySelector('.blog-back-to-top').style.display = 'none';
    };
  };

  window.addEventListener("scroll", function(){
    amountscrolled();
  }, false);
});
